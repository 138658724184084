import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert'; 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { put } from 'aws-amplify/api'; 
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";  
// import MuiAccordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails'; 
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import { styled } from '@mui/material/styles';
// import pdfFile from './tasking_guide.pdf';  
import { getUrl } from 'aws-amplify/storage';

// const Accordion = styled((props) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&::before': {
//     display: 'none',
//   },
// }));

// const docs = [ 
//     { uri: require("./tasking_guide.pdf") }, // Local File
//   ];
  
const ERROR_MESSAGE = "Platform, tasking type and/or tasking value field(s) are empty!";
const SUCCESS_MESSAGE = "Successfully submitted tasking. Activate entry ";
const platforms = [
    "Facebook", 
    "Instagram",
    "YouTube",
    "TikTok",
    "Twitter"
    ];
const tasking_type = [ 
    "Hashtag", 
    "Keyword",
    "Account"
    ]; 

export default function AddTaskingForm(props) {  
    const [platform, setPlatform] = useState('');  
    const [taskingType, setTaskingType] = useState('');
    const [hashtag, setHashtag] = useState(''); // If tasking type is 'Hashtag'
    const [keyword, setKeyword] = useState(''); // If tasking type is 'Keyword'
    const [accountID, setAccountID] = useState(''); // If tasking type is 'Activity'
    const [accountName, setAccountName] = useState(''); // If tasking type is 'Activity'
    const [accountURL, setAccountURL] = useState(''); // If tasking type is 'Activity' 
    const [accountUsername, setAccountUsername] = useState(''); // If tasking type is 'Activity' 
    const [status, setStatus] = useState(null); 
    const [openDialog, setOpenDialog] = useState(false);  
    const [openAlert, setOpenAlert] = useState(false);  
    const [taskingGuideUrl, setTaskingGuideUrl] = useState("");
    
    useEffect(() => { 
        const getUrlResult = async () => {
          try { 
            const url = await getUrl({
              key: 'taskingguide.pdf',
              options: {
                accessLevel: 'public',  
                validateObjectExistence: false,  
              },
            })  
            return url.url.toString();
          } catch (err) {
            console.log(err);
          }
        } 
        getUrlResult().then((value) => setTaskingGuideUrl(value)); 
    }, []);
    
 
 
    const addHashtagTasking = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: pk,
                        platform: platform, 
                        tasking_type: taskingType,
                        tasking_value: hashtag,
                        hashtag: hashtag,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        is_active: false,
                        date_modified: 'N.A.',
                        latest_status: 'N.A.',
                        last_modifier: 'N.A.',
                        account_name: 'N.A.',
                        team: props.user.team,
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
    
    const addKeywordTasking = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: pk,
                        platform: platform,
                        tasking_type: taskingType,
                        tasking_value: keyword,
                        keyword: keyword,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        is_active: false,
                        date_modified: 'N.A.',
                        latest_status: 'N.A.',
                        last_modifier: 'N.A.',
                        account_name: 'N.A.',
                        team: props.user.team,
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
    
    const addAccountTasking = async (pk) => {
        try {
            const putOperation = put({
                apiName: 'hctaskingapi',
                path: '/hctasking',
                options: {
                    body: {
                        PK: pk,
                        platform: platform,
                        tasking_type: taskingType,
                        tasking_value: accountID + "," + accountName + "," + accountUsername + "," + accountURL,
                        account_id: accountID,
                        account_name: accountName,
                        account_url: accountURL,
                        account_username: accountUsername,
                        creator: props.user.username,
                        date_created: new Date().toLocaleString(), 
                        is_active: false,
                        date_modified: 'N.A.',
                        latest_status: 'N.A.',
                        last_modifier: 'N.A.',
                        team: props.user.team,
                        delete: false
                    }
                }
            })
            await putOperation.response; 
        } catch (err) {
            setStatus(false); // Different error message needed
            console.log('PUT call failed: ', err);
        }
    }
 
    const handleConfirm = () => {
        if (platform === '' || taskingType ==='') {
            setStatus(false);
            setOpenAlert(true);
        } else if ((taskingType === 'Hashtag' && hashtag.trim() === '') || 
        (taskingType === 'Keyword' && keyword.trim() === '') || 
        (taskingType === 'Account' && (accountID.trim() === '' || accountName.trim() === '' || accountURL.trim() === '' || accountUsername.trim() === ''))) {
            setStatus(false);
            setOpenAlert(true);     
        } else { 
            const pk = uuid().slice(0, 12); 
            setStatus(true);
            setOpenAlert(true);  
            if (taskingType === 'Hashtag') {
                addHashtagTasking(pk); 
            } else if (taskingType === 'Keyword') {
                addKeywordTasking(pk);
            } else if (taskingType === 'Account') {
                addAccountTasking(pk); 
            } else {
                console.log('Error: Tasking type does not exist.');
            }            
        } 
        setOpenDialog(false);   
    } 
    
    const handleReset = () => {
        setPlatform('');
        setTaskingType(''); 
        setHashtag('');
        setKeyword('');
        setAccountID('');
        setAccountName('');
        setAccountURL('');
        setAccountUsername('');
        setStatus(null);
        setOpenAlert(false);
    }
    
    const handleOpen = () => {
        setOpenDialog(true);
    } 
    
    const handleClose = () => {
        setOpenDialog(false);
    } 
    
    let alert; 
    if (status) {
        alert = <Alert sx={{fontSize: '16px'}} severity="success" onClose={() => setOpenAlert(false)}>{SUCCESS_MESSAGE}<Link to="/social-media-tasking/manage-team">here</Link>.</Alert>; 
    } else if (status === null) {
        alert = <div></div>;
    } else {
        alert = <Alert sx={{ fontSize: '16px' }} severity="error" onClose={() => setOpenAlert(false)}>{ERROR_MESSAGE}</Alert>;
    }
    
    return (
        <Box className='table-position' sx={{ paddingBottom: '1rem' }}> 
            <React.Fragment> 
              <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please ensure that this record <strong>does not</strong> contain any code words.<br/>Are you sure you want to submit record?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className='dialog-button' onClick={handleConfirm}>
                        <span className='dialog-button-text'>
                            CONFIRM
                        </span> 
                    </Button>
                    <Button variant="outlined" className='dialog-button' onClick={handleClose} autoFocus>
                        <span className='dialog-button-text'>
                            CANCEL
                        </span> 
                    </Button>
                </DialogActions>
              </Dialog>
            </React.Fragment> 
            <Paper elevation={0} className='table' > 
                    <Box> 
                        <div className='table-header'>
                            Platform
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                            <FormControl fullWidth>
                                <InputLabel size="small">Select a platform</InputLabel>
                                <Select  
                                  value={platform}
                                  label="Select a platform"
                                  size="small"
                                  onChange={(event) => setPlatform(event.target.value)}
                                >
                                {platforms.map(
                                    (type) => ( 
                                        <MenuItem value={type}>{type}</MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>
                        </Box>
                        <div className='table-header'>
                            Tasking Type
                        </div>
                        <Box sx={{paddingLeft: '1%', paddingRight: '1%'}}>
                            <FormControl fullWidth>
                                <InputLabel size="small">Select a tasking type</InputLabel>
                                <Select  
                                  value={taskingType}
                                  label="Select a tasking type"
                                  size="small"
                                  onChange={(event) => setTaskingType(event.target.value)}
                                >
                                {tasking_type.map(
                                    (type) => ( 
                                        <MenuItem value={type}>{type}</MenuItem>
                                    ))
                                }
                                </Select>
                            </FormControl>
                        </Box>
                        {(taskingType === 'Hashtag') ?
                            <><div className='table-header'>
                                Tasking Value
                            </div> 
                            <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                                <FormControl fullWidth> 
                                    <TextField 
                                        value={hashtag}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Hashtag"
                                        onChange={(event) => setHashtag(event.target.value)}
                                    />     
                                </FormControl>
                            </Box> </>:
                            <div></div> 
                        }
                        {(taskingType === 'Keyword') ?
                            <><div className='table-header'>
                                Tasking Value
                            </div> 
                            <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                                <FormControl fullWidth>                          
                                    <TextField 
                                        value={keyword}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Keyword"
                                        onChange={(event) => setKeyword(event.target.value)}
                                    />     
                                </FormControl>
                            </Box> </>:
                            <div></div> 
                        }
                        {(taskingType === 'Account') ?
                            <><div>
                                <p className='table-header'> 
                                    Tasking Value
                                </p>
                            </div>  
                            <Box sx={{paddingLeft: '20px', paddingBottom: '5px' }}> <i>Need help? Refer to our <a href={taskingGuideUrl} download="taskingguide.pdf" target="_blank" rel="noreferrer">tasking guide</a>.</i></Box>
                            <Box sx={{paddingLeft: '1%', paddingRight: '1%'}} component="form" noValidate autoComplete="off">
                                <FormControl fullWidth> 
                                    <TextField 
                                        value={accountID}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account ID"
                                        onChange={(event) => setAccountID(event.target.value)}
                                    />     
                                </FormControl>
                                <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                    <TextField 
                                        value={accountName}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account Name"
                                        onChange={(event) => setAccountName(event.target.value)}
                                    />     
                                </FormControl>
                                <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                    <TextField 
                                        value={accountUsername}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account Username"
                                        onChange={(event) => setAccountUsername(event.target.value)}
                                    />     
                                </FormControl>
                                <FormControl fullWidth sx={{paddingTop: '0.7rem'}}> 
                                    <TextField 
                                        value={accountURL}
                                        id="outlined-basic" 
                                        size="small" 
                                        variant="outlined" 
                                        label="Account URL"
                                        onChange={(event) => setAccountURL(event.target.value)}
                                    />     
                                </FormControl>
                            </Box> </>:
                            <div></div> 
                        }
                    </Box>
                    <Box className='button-box-position'>
                        <div className='button-position'>
                            <Button className='form-button' onClick={handleOpen} variant="contained">
                                <span className='button-text'>
                                    SUBMIT
                                </span> 
                            </Button>
                            <Button className='form-button' onClick={handleReset} variant="contained">
                                <span className='button-text'>
                                    RESET
                                </span>  
                            </Button>
                        </div>
                    </Box> 
            </Paper>  
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={openAlert}> 
                {alert} 
            </Snackbar>  
        </Box>
    );
} 


            // <Box sx={{paddingBottom: '1rem'}}>
            //     <Accordion elevation={0} disableGutters>
            //         <AccordionSummary
            //           expandIcon={<ArrowDropDownIcon />} 
            //           sx={{ backgroundColor: '#f5f5f5' }}
            //         >   
            //             <Box sx={{ display: 'flex', gap: '10px' }}>
            //                 <div className='sub-header'>
            //                     Tasking Account Guide
            //                 </div> 
            //             </Box>
            //         </AccordionSummary>
            //         <AccordionDetails>
            //           <iframe src={pdfFile} title="Tasking Account Guide" width="100%" height="800"/>
            //         </AccordionDetails>
            //     </Accordion>     
            // </Box> 